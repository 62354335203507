.multi-list ul {
    overflow-y: visible;
    width: 100%;
    font-size: small;
}

/* input[type="checkbox"] {
    display: none;
} */

